html,
body {
  min-height: 100vh;
  overflow-y: inherit;
}

body,
#root {
  display: flex;
  flex-direction: column;
}

#root,
#root > div {
  flex: 1;
}

/**
* Utils
*/

.container.is-fullwidth {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.is-visible {
  visibility: visible !important;
  opacity: 1 !important;
}

a.is-active {
  font-weight: 600;
}

/*
* Mega Navbar
*/
.navbar-item.is-mega {
  position: static;
}
.navbar-item.is-mega .is-mega-menu-title {
  margin-bottom: 0;
  padding: 0.375rem 1rem;
}

div.navbar-item {
  color: #4a4a4a;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
  cursor: pointer;
}

/**
* Printing utils
*/
@media print {
  .no-print,
  .no-print *,
  .navbar,
  .footer {
    display: none !important;
  }
  @page {
    size: landscape;
  }
}

/**
* CSS fixes
*/

@media screen and (max-width: 600px) {
  html .columns {
    margin-left: 0;
    margin-right: 0;
  }
}

.selectableList {
  flex: 1;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selectableList .panel {
  flex: 1;
  overflow: auto;
}

/**
* Custom scrollbar
*/

.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
