/*
* Custom Table Styles
*/
.onHover-show-btn {
  cursor: pointer;
}
.onHover-show-btn > button {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s all ease;
}

.onHover-show-btn:hover button,
.onHover-show-btn:focus-within button {
  visibility: visible;
  opacity: 1;
}

.btn-sorting {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1;
}

.btn-sorting:focus,
.btn-sorting:hover {
  background: rgba(0, 0, 0, 0.2);
}

button.dropdown-item {
  background: none;
  border: none;
  cursor: pointer;
}

button.dropdown-item.selected {
  background: rgba(0, 0, 0, 0.2);
}
html .action-cell {
  max-width: 40px;
  text-align: right !important;
}

.toolbar {
  display: flex;
}

.margin-auto-left {
  margin-left: auto;
}

@media print {
  @page {
    size: landscape;
  }
  table {
    border-collapse: collapse;
    page-break-after: auto;
  }

  .no-print,
  .no-print *,
  .awesome-table > .toolbar {
    display: none !important;
  }

  table.table td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .table-container {
      overflow-x: hidden;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-row-group;
  }
  tbody {
    display: table-row-group;
  }
  tfoot {
    display: table-footer-group;
  }
}

/*
    Responsive Table styles
  */
/* 
  @media screen and (max-width: 800px) {
    .is-responsive {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      display: block;
      position: relative;
    }
    .is-responsive td:empty:before {
      content: " ";
    }
    .is-responsive th,
    .is-responsive td {
      margin: 0;
      vertical-align: top;
    }
    .is-responsive th {
      text-align: left;
    }
    .is-responsive thead {
      border-right: solid 2px #dbdbdb;
      display: block;
      float: left;
    }
    .is-responsive thead tr {
      display: block;
    }
    .is-responsive thead tr th::before {
      content: " ";
    }
    .is-responsive thead td,
    .is-responsive thead th {
      border-width: 0 0 1px;
    }
    .is-responsive tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap;
    }
    .is-responsive tbody tr {
      display: inline-block;
      vertical-align: top;
    }
    .is-responsive th {
      display: block;
      text-align: right;
    }
    .is-responsive td {
      display: block;
      min-height: 1.25em;
      text-align: left;
    }
    .is-responsive th:last-child,
    .is-responsive td:last-child {
      border-bottom-width: 0;
    }
    .is-responsive tr:last-child td:not(:last-child) {
      border: 1px solid #dbdbdb;
      border-width: 0 0 1px;
    }
    .is-responsive.is-bordered td,
    .is-responsive.is-bordered th {
      border-width: 1px;
    }
    .is-responsive.is-bordered tr td:last-child,
    .is-responsive.is-bordered tr th:last-child {
      border-bottom-width: 1px;
    }
    .is-responsive.is-bordered tr:last-child td,
    .is-responsive.is-bordered tr:last-child th {
      border-width: 1px;
    }
  } */
